import http from "../axios/http"

//终端首页信息（终端）
export let getHome = (params) => {
    return http.get("/index/user_info", { params })
}
//首页今日数据（终端）
export let getTodayData = (params) => {
    return http.get("/index/day_info", { params })
}
