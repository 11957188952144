<template>
  <div class="zhuye">
    <div class="zhuye-top">
      <div class="top-left">
        <img v-if="userInfo.avatar" :src="userInfo.avatar" />
        <img v-else src="../assets/avator.jpg" />
        <div>
          <div class="hello">嗨，{{ userInfo.username }}，很高兴见到你！</div>
          <label>{{ userInfo.company_name }}</label>
          <span class="banben">{{
            userInfo.type === "terminal" ? "终端" : "坐席端"
          }}</span>
        </div>
      </div>
      <div class="top-right">
        <div class="item">
          <div>账号数</div>
          <span>2</span>
        </div>
        <div class="item">
          <div>个人工作中AI坐席/最大可用AI坐席</div>
          <span>12</span>/<label>0</label>
        </div>
        <div class="item">
          <div>公司工作中AI坐席/总AI坐席</div>
          <span>23</span>/<label>0</label>
        </div>
      </div>
    </div>
    <div class="zhuye-content">
      <div class="today-data">
        <h3 class="today-data-title">今日数据</h3>
        <div class="today-data-box">
          <div class="today-data-item">
            <div class="top">智能外呼数</div>
            <div>
              <span>{{ todayData.total_number }}</span
              >个
            </div>
          </div>
          <div class="today-data-item">
            <div class="top">接通数</div>
            <div>
              <span>{{ todayData.capacity_number }}</span
              >个
            </div>
          </div>
          <div class="today-data-item">
            <div class="top">接通率</div>
            <div>
              <span>{{
                (todayData.capacity_number / todayData.total_number) * 100 || 0
              }}</span
              >%
            </div>
          </div>
          <div class="today-data-item">
            <div class="top">通话总时长</div>
            <div>
              <span>{{ Math.ceil(todayData.total_duration / 60) }}</span
              >分钟
            </div>
          </div>
          <div class="today-data-item">
            <div class="top">平均通话时长</div>
            <div>
              <span>{{
                Math.ceil(
                  todayData.total_duration / todayData.capacity_number || 0
                )
              }}</span
              >秒
            </div>
          </div>
          <div class="today-data-item">
            <div class="top">意向客户</div>
            <div>
              <span>{{
                todayData.one_number +
                todayData.two_number 
              }}</span
              >个
            </div>
          </div>
          <div class="today-data-item">
            <div class="top">人工跟进数</div>
            <div>
              <span>{{ todayData.man_machine_number }}</span
              >个
            </div>
          </div>
        </div>
      </div>
      <div class="custom">
        <div class="custom-top">
          <h3 class="custom-title">意向客户</h3>
          <div>
            <a-select
              style="width: 140px; margin-right: 15px"
              placeholder="选择话术"
              size="small"
              v-model:value="params.speech_id"
              @change="speeceChange"
            >
              <a-select-option
                v-for="item in speech_list"
                :value="item.talk_temp_id"
                :key="item.talk_temp_id"
                >{{ item.talk_temp_name }}</a-select-option
              >
            </a-select>
            <a-range-picker
              style="width: 240px"
              size="small"
              v-model:value="params.date"
              valueFormat="YYYY-MM-DD"
              :disabledDate="disabledDay"
              :allowClear="false"
              @change="dataChange"
            />
          </div>
        </div>
        <div class="custom-box">
          <div style="margin-top: 20px">
            <Echarts :option="option" id="myEcharts" />
          </div>
          <div><Echarts :option="option2" id="myEcharts2" /></div>
        </div>
      </div>
      <div class="custom">
        <div class="custom-top">
          <h3 class="custom-title">通话数量=>改为实时并发数</h3>
        </div>
        <div class="custom-box">
          <div style="width: 100%; margin-top: 20px">
            <Echarts :option="option3" id="myEcharts3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
// import { onMounted, reactive, ref, toRefs } from "vue";
import Echarts from "../components/Echarts2";
import { getHome, getTodayData } from "../service/zhuye";
import { onMounted } from "@vue/runtime-core";
import { getList, getSpeechList } from "../service/outbound"; //外呼报表数据
import { formDate } from "../utils/function";
import moment from "moment";
export default {
  name: "Zhuye",
  components: {
    Echarts,
  },
  setup() {
    const state = reactive({
      // value: ["2021/12/02", "2022/01/02"],
      option: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "A级(明确有意向)",
            "B级(可能有意向)",
            "C级(明确拒绝)",
            "D级(用户忙)",
            "E级(拨打失败)",
            "F级(无效客户)",
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "A级(明确有意向)",
            type: "line",
            stack: "Total1",
            data: [1, 2, 2, 4, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "B级(可能有意向)",
            type: "line",
            stack: "Total2",
            data: [2, 2, 4, 2, 6, 2, 1, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "C级(明确拒绝)",
            type: "line",
            stack: "Total3",
            data: [0, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4, 1, 2, 2],
          },
          {
            name: "D级(用户忙)",
            type: "line",
            stack: "Total4",
            data: [6, 2, 2, 2, 3, 1, 2, 3, 4, 1, 2, 2, 4, 2],
          },
          {
            name: "E级(拨打失败)",
            type: "line",
            stack: "Total5",
            data: [1, 2, 2, 2, 3, 1, 2, 3, 4, 2, 2, 4, 2, 6],
          },
          {
            name: "F级(无效客户)",
            type: "line",
            stack: "Total6",
            data: [2, 2, 3, 1, 2, 1, 2, 2, 4, 2, 6, 2, 3, 4],
          },
        ],
      },
      option2: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
              { value: 300, name: "Video Ads" },
            ],
          },
        ],
      },
      option3: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["外呼量", "接通量"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "外呼量",
            type: "line",
            stack: "Total1",
            data: [1, 2, 2, 4, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "接通量",
            type: "line",
            stack: "Total2",
            data: [2, 2, 4, 2, 6, 2, 1, 2, 2, 3, 1, 2, 3, 4],
          },
        ],
      },
      userInfo: {},
      todayData: {},
      speech_list: [],
    });
    const params = reactive({
      speech_id: undefined,
      date: [],
    });
    onMounted(async () => {
      params.date = [
        formDate(Date.now() - 1000 * 60 * 60 * 24 * 30),
        formDate(Date.now()),
      ];
      const res = await getTodayData({
        access_token: sessionStorage.getItem("token"),
      });
      // console.log(res);
      state.todayData = res.data.data;
      const res2 = await getHome({
        access_token: sessionStorage.getItem("token"),
      });
      state.userInfo = res2.data.data;

      const res3 = await getSpeechList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res3);
      state.speech_list = res3.data.data;
      if (state.speech_list.length !== 0) {
        params.speech_id = state.speech_list[0].talk_temp_id;
      }
      init();
    });
    const init = async () => {
      console.log(params.date);
      const request = {
        date: JSON.stringify({ start: params.date[0], end: params.date[1] }),
      };
      if (params.speech_id) {
        request.speech_id = params.speech_id;
      }
      console.log(request);
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
        // speech_id: params.speech_id,
        // date: JSON.stringify({ start: params.date[0], end: params.date[1] }),
        ...request,
      });
      console.log(res);
      const { time_range, label_list, A, B, C, D, E, F, all_type, all_call } = res.data.data;
      state.option.legend.data = label_list;
      state.option.xAxis.data = time_range;
      state.option3.xAxis.data = time_range;
      state.option.series[0].name = label_list[0];
      state.option.series[0].data = A;
      state.option.series[1].name = label_list[1];
      state.option.series[1].data = B;
      state.option.series[2].name = label_list[2];
      state.option.series[2].data = C;
      state.option.series[3].name = label_list[3];
      state.option.series[3].data = D;
      state.option.series[4].name = label_list[4];
      state.option.series[4].data = E;
      state.option.series[5].name = label_list[5];
      state.option.series[5].data = F;
      console.log(state);
      let arr = [];
      for (let key in all_type) {
        arr.push(all_type[key]);
      }
      label_list.forEach((item, index) => {
        state.option2.series[0].data[index].name = item;
        state.option2.series[0].data[index].value = arr[index];
      });
      state.option3.series[0].data = all_call.capacity_number;
      state.option3.series[1].data = all_call.total_number;
    };
    const disabledDay = (current) => {
      // 只能选择当前日期的两个月范围内
      return (
        current &&
        (current > moment().endOf("day") ||
          current < moment().subtract(1, "months"))
      );
    };
    const speeceChange = (e) => {
      console.log(e);
      params.speech_id = e;
      init();
    };
    const dataChange = (e) => {
      console.log(e);
      params.data = e;
      init();
    };
    return { ...toRefs(state), disabledDay, params, speeceChange, dataChange };
  },
};
</script>

<style scoped>
.zhuye {
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* overflow: auto; */
  overflow-y: auto;
  overflow-x: hidden;
}
.zhuye::-webkit-scrollbar {
  display: none;
}
.zhuye-top {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  align-items: center;
  border-bottom: 4px solid #f0f2f5;
}
.top-left {
  display: flex;
  align-items: center;
}
.top-left img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 25px;
}
.top-left label {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  font-size: 14px;
}
.hello {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}
.banben {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
  padding: 2px 4px;
  margin-left: 10px;
  border-radius: 2px;
  cursor: pointer;
}
.top-right {
  display: flex;
}
.top-right .item {
  text-align: center;
  padding: 0 15px;
  /* border-left: 1px solid #ccc; */
}
.top-right .item:nth-last-child(1) {
  padding-right: 0px !important;
}
.top-right .item div {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.top-right .item span {
  font-size: 30px;
  line-height: 38px;
}
.top-right .item label {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 4px;
  font-size: 14px;
}
.zhuye-content {
  padding: 0 15px;
}
.today-data {
  margin: 10px 0;
}
.today-data-box {
  border-top: 1px solid rgb(243, 237, 237);
  border-bottom: 1px solid rgb(243, 237, 237);
  margin: 20px 0;
  display: flex;
}
.today-data-item {
  margin: 22px 0;
  text-align: center;
  width: 15%;
  border-right: 1px solid rgb(243, 237, 237);
}
.today-data-item:nth-last-child(1) {
  border-right: none;
}
.today-data-item .top {
  margin-bottom: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.today-data-item div span {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin-right: 4px;
}
/* .custom-title {
  
} */
.custom {
  margin-bottom: 30px;
}
.custom-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(243, 237, 237);
  padding-bottom: 10px;
}
.custom-box {
  display: flex;
  height: 400px;
}
.custom-box div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>